<template>
  <div class="d-flex align-items-center">
    <div class="mar-top-60 w-100">
      <div class="bind-page">
        <div class="bg-panel">
          <img src="@/assets/image/home/leftHeight.svg" width="560" style="margin-right:55px" alt="">
        </div>
        <div class="bind-panel">
          <p class="title">登录账号</p>
          <div class="btn-s">
            <div class="items">
              <p class="btn-desc">如果您没有注册过GOGO2GO账号，需要新注册</p>
              <button type="button" class="btn mall-button mall-button--primary mall-button--normal" @click="registerBtn()">
                <span>点我注册</span>
              </button>
            </div>
            <div class="items">
              <p class="btn-desc">如果您之前注册过GOGO2GO账号且注册成功</p>
              <button type="button" class="btn mall-button mall-button--primary mall-button--normal" @click="loginBtn()">
                <span>点击下载</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    registerBtn() {
      this.$router.push({ path: '/signIn/register' })
    },
    loginBtn() {
		
    }
  }
}
</script>
<style scoped>
.bind-page {
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
}
.bind-page .bg-panel {
  flex: 1;
  height: 300px;
  border-right: 2px dashed #dee6f1;
}
.bind-page .bind-panel {
  height: 300px;
  flex: 1;
  padding-left: 50px;
}
.bind-page .bind-panel .title {
  font-weight: 500;
  line-height: 22px;
  font-size: 22px;
  color: #192537;
  letter-spacing: 0.8px;
}
.bind-page .bind-panel .desc {
  width: 550px;
  margin-top: 24px;
  font-size: 14px;
  color: rgba(25, 37, 55, 0.5);
  letter-spacing: 0.4px;
  line-height: 20px;
}
.bind-page .bind-panel .btn-s {
  width: 550px;
  margin-top: 40px;
}
.bind-page .bind-panel .btn-s .items {
  box-sizing: border-box;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 46px;
  padding: 20px 24px;
  background: #f2f5fa;
}
.bind-page .bind-panel .btn-s .items .btn-desc {
  font-size: 14px;
  color: #192537;
  line-height: 20px;
  margin-right: 10px;
  word-break: break-word;
  letter-spacing: 0.4px;
}
.bind-page .bind-panel .btn-s .items .btn {
  width: 180px;
  height: 40px;
  border-radius: 5px;
}
.mall-button--primary {
  color: #fff;
  background-color: #4285f7;
  border-color: #4285f7;
}
/*@media screen and (max-width: 997px) {*/
/*  .bind-page {*/
/*    width: 100%;*/
/*    flex-direction: column;*/
/*    padding: 10px 0;*/
/*  }*/
/*  .bind-panel {*/
/*    padding: 0 10px !important;*/
/*    box-sizing: border-box;*/
/*  }*/
/*  .bind-panel .desc {*/
/*    width: 100% !important;*/
/*  }*/
/*  .bind-panel .btn-s {*/
/*    width: 100% !important;*/
/*  }*/
/*}*/
</style>
